<template>
  <v-row>
    <PlacesOfService @changes="checkChanges" />
  </v-row>
</template>

<script>
import { mdiSync } from '@mdi/js'
import PlacesOfService from '@/components/features/PlacesOfService.vue'

export default {
  components: { PlacesOfService },
  data() {
    return {
      changes: false,
    }
  },
  methods: {
    checkChanges(event) {
      this.changes = event
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.changes) {
      this.$root.confirm({
        title: 'Any unsaved changes will be lost',
        confirm: 'Continue',
        confirmIcon: mdiSync,
        confirmColor: 'error',
      }).then(result => {
        if (result === true) {
          next()
        } else {
          next(false)
        }
      })
    } else {
      next()
    }
  },
}
</script>
