<template>
  <v-alert
    v-model="disableAlert"
    :cols="12"
    color="warning"
    icon="$warning"
    width="100%"
    class="mt-4 mx-3 mb-0"
  >
    <div class="text-h6 ">
      Warning
    </div>
    <div class="word-break">
      You can only have up to {{ maxFacilities }} facilities. No additional facilities can be added.
    </div>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    disableAlert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('facilities', ['maxFacilities']),
  },

  methods: {
    // Too many facilities in go list
    maxFacilitiesError() {
      return this.selectedFacilitiesLength - this.maxFacilities
    },
  },
};
</script>
